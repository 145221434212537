import { Expose, Transform, Type } from 'class-transformer'

import { BookEnum } from '@interfaces/BookEnum'
import { PromotionEbookType } from './PromotionEbookType'
import { WishlistCategoryType } from './WishlistCategoryType'
import { WishlistTagType } from './WishlistTagType'

export class EbookPurchaseType {
  @Expose() id: number

  @Expose() title: string

  @Expose()
  @Transform(({ obj }) => obj.penName.firstPenName || '')
  penName: string

  @Expose()
  @Transform(({ obj }) => obj.penName.user.profilePageSlug || '')
  profilePageSlug: string

  @Expose() coverImgPath: string

  @Expose() cover: string

  @Expose() bookType: BookEnum

  @Expose()
  @Transform(({ value }) => value || 0)
  avgRating: number

  @Expose()
  @Transform(({ value }) => value || 0)
  reviewCount: number

  @Expose()
  @Transform(({ value }) => value || 0)
  wishlistCount: number

  @Expose() price: number

  @Expose() isWishlist: boolean

  @Expose() @Type(() => WishlistCategoryType) category: WishlistCategoryType

  @Expose() @Type(() => PromotionEbookType) promotion?: PromotionEbookType

  @Expose()
  @Type(() => WishlistTagType)
  tags: WishlistTagType[]
}
