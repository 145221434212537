import { Expose, Transform, Type } from 'class-transformer'

import { EbookLogEnum } from '@interfaces/EbookLogEnum'
import { WishlistEbookType } from './WishlistEbookType'

export class WishlistType {
  @Expose() id: number

  @Expose() ebookId: number

  @Expose()
  @Type(() => WishlistEbookType)
  ebook: WishlistEbookType

  @Expose()
  @Transform(({ obj }) => obj.user?.profilePageSlug)
  profilePageSlug: string

  @Expose() types: EbookLogEnum

  @Expose() isReaded: boolean
}
