import { Expose, Type } from 'class-transformer'

import { WishlistType } from './WishlistType'

export class WishlistResponse {
  @Expose()
  @Type(() => WishlistType)
  data: WishlistType[]

  @Expose() total: number

  @Expose() page: number
}
