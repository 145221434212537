import React from 'react'
import cn from 'classnames'

import { StarEmptyIcon } from '@components/icons/StarEmptyIcon'
import { StarSolidIcon } from '@components/icons/StarSolidIcon'
import { StarRatingProps } from './interface'

export function StarRating({
  className,
  starsWidth,
  starHeight,
  ratingContainerRef,
  totalStar,
  isHovered,
  hoverActiveStar,
  activeStar,
  viewOnly,
  handleClick,
  handleMouseMove,
  handleMouseLeave,
}: StarRatingProps) {
  return (
    <div
      className={cn('flex', className)}
      onClick={!viewOnly ? handleClick : undefined}
      onMouseMove={!viewOnly ? handleMouseMove : undefined}
      onMouseLeave={!viewOnly ? handleMouseLeave : undefined}
      ref={ratingContainerRef}
    >
      {[...new Array(totalStar)].map((arr, index) => {
        const activeState = isHovered ? hoverActiveStar : activeStar
        const resultActiveState = (activeState % 1) * 100

        const showEmptyIcon = activeState === -1 || activeState < index + 1

        const isActiveRating = activeState !== 1
        const isRatingWithPrecision = activeState % 1 !== 0
        const isRatingEqualToIndex = Math.ceil(activeState) === index + 1
        const showRatingWithPrecision =
          isActiveRating && isRatingWithPrecision && isRatingEqualToIndex
        return (
          <div
            className={cn('relative', { 'cursor-pointer': !viewOnly })}
            key={index}
          >
            <div
              style={{
                width: showRatingWithPrecision
                  ? resultActiveState > 79
                    ? `100%`
                    : resultActiveState > 29
                    ? `50%`
                    : `0%`
                  : '0%',
                overflow: 'hidden',
                position: 'absolute',
              }}
            >
              <StarSolidIcon
                className='text-primary'
                width={starsWidth}
                height={starHeight}
              />
            </div>
            <div
              style={{
                color: showEmptyIcon ? 'gray' : 'inherit',
              }}
            >
              {showEmptyIcon ? (
                <StarEmptyIcon
                  className='text-primary'
                  width={starsWidth}
                  height={starHeight}
                />
              ) : (
                <StarSolidIcon
                  className='text-primary'
                  width={starsWidth}
                  height={starHeight}
                />
              )}
            </div>
          </div>
        )
      })}
    </div>
  )
}
