import cn from 'classnames'

import { abbreviateNumber, addComma, formatAvgRating } from '@lib/utils'
import { StarSolidIcon } from '@components/icons/StarSolidIcon'
import { HeartEbookIcon } from '@components/icons/HeartEbookIcon'
import { HeartEbookSolidIcon } from '@components/icons/HeartEbookSolidIcon'
import { PromotionEbookType } from '@models/ebook/PromotionEbookType'
import { WishlisEnum } from '@models/ebook/WishlishEnum'

interface MobileTabletFooterProps {
  id: number
  wishlistCount?: number
  direction?: string
  promotion?: PromotionEbookType
  price?: number
  avgRating: number
  isWishlist?: boolean
  isToggleWishlist?: boolean
  isPurchased?: boolean
  showPrice: boolean
  handleWishlist?: (id: number) => Promise<WishlisEnum>
}

export function MobileTabletFooter({
  id,
  wishlistCount,
  direction,
  promotion,
  price,
  avgRating,
  isWishlist,
  isToggleWishlist,
  isPurchased,
  showPrice,
  handleWishlist,
}: MobileTabletFooterProps) {
  return (
    <section className='flex justify-between items-center text-[12px] font-light text-secondary desktop:hidden'>
      <div
        className={cn('flex flex-col items-center', {
          'flex-col': direction === 'vertical',
        })}
      >
        <StarSolidIcon className='text-primary' width='18' height='18' />
        <span className='mx-[5px] mobile:text-[12px] font-medium'>
          {formatAvgRating(avgRating || 0)}
        </span>
      </div>
      {showPrice && (
        <div
          className={cn('flex flex-col items-center', {
            'flex-col': direction === 'vertical',
          })}
        >
          <img className='w-[18px] h-[18px]' src='/coin.webp' alt='M Coin' />
          {promotion ? (
            !promotion.isPublish ? (
              <>
                <span className='font-medium text-yellow line-clamp-1 break-all'>
                  {addComma(price!)}
                </span>
              </>
            ) : (
              <>
                <div className='text-secondary-1 font-light line-clamp-1 break-all'>
                  <span className='font-medium text-yellow'>
                    &nbsp;{addComma(promotion.discountPrice)}&nbsp;
                  </span>
                  <span className='line-through'>
                    ({addComma(promotion.price)})
                  </span>
                </div>
              </>
            )
          ) : (
            <>
              <span className='font-medium text-yellow line-clamp-1 break-all'>
                {addComma(price!)}
              </span>
            </>
          )}
        </div>
      )}

      {!isPurchased ? (
        <button
          className={cn(
            'px-[10px] py-[4px] border border-gray-4 rounded-[8px] flex flex-col justify-center items-center',
            {
              'text-secondary': isWishlist,
              'text-secondary-1': !isWishlist,
            }
          )}
          type='button'
          disabled={isToggleWishlist}
          onClick={async (event: React.MouseEvent<HTMLButtonElement>) => {
            event.stopPropagation()
            if (handleWishlist) await handleWishlist(id)
          }}
        >
          <HeartEbookIcon
            className={cn('text-secondary', {
              'mr-0': direction === 'horizontal',
              'flex-col': direction === 'vertical',
              hidden: isWishlist,
            })}
            width='20'
            height='20'
          />
          <HeartEbookSolidIcon
            className={cn('text-red', {
              'flex-col': direction === 'vertical',
              hidden: !isWishlist,
            })}
            width='20'
            height='20'
          />
          {wishlistCount !== undefined ? (
            <span className='text-secondary-1'>
              {abbreviateNumber(wishlistCount)}
            </span>
          ) : null}
        </button>
      ) : null}
      {isPurchased ? (
        <div className='bg-blue-2 px-[10px] py-[6px] rounded-[4px] text-[12px] font-right text-primary'>
          ซื้อแล้ว
        </div>
      ) : null}
    </section>
  )
}
