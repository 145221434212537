import { DateTime } from 'luxon'
import { useEffect, useState } from 'react'

interface PromotionTimeProps {
  discountPercent: number
  endPublishedAt: string
}

export function PromotionTime({
  discountPercent,
  endPublishedAt,
}: PromotionTimeProps) {
  const endTime = DateTime.fromISO(endPublishedAt)
  const [text, setText] = useState(cal())

  function cal() {
    const { days, hours, minutes, seconds } = endTime.diffNow([
      'days',
      'hours',
      'minutes',
      'seconds',
    ])

    if (days > 7) {
      return `ถึง ${endTime.toJSDate().toLocaleDateString('th-TH', {
        day: 'numeric',
        month: 'short',
      })}`
    }

    if (days >= 1) {
      return `${days} วันสุดท้าย`
    }

    return `${hours}:${minutes}:${seconds.toFixed(0)}`
  }

  useEffect(() => {
    const intervalFunc = setInterval(() => setText(cal()), 1000)

    return () => clearInterval(intervalFunc)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className='absolute left-0 top-0 text-center rounded-[8px] border border-red bg-gradient-to-b from-red to-red-6 p-[5px] text-white font-medium w-[68px]'>
      <div className='text-[14px]'>{`-${discountPercent}%`}</div>
      <div className='text-[12px] font-light opacity-80 whitespace-nowrap'>
        {text}
      </div>
    </div>
  )
}
