import { gql } from 'graphql-request'

export const CREATE_EBOOK_WISHLIST_LOG = gql`
  mutation CreateEbookWishlistLogs(
    $createEbookWishlistLogsInput: CreateEbookWishlistLogsInput!
  ) {
    createEbookWishlistLogs(
      CreateEbookWishlistLogsInput: $createEbookWishlistLogsInput
    ) {
      deletedAt
    }
  }
`
