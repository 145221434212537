import { plainToInstance } from 'class-transformer'

import { GET_MY_EBOOK_WISHLIST_LOGS } from '@client/collections/Ebook/schemas/getMyEbookWishlistLogs'
import { gqlApiInstance } from '@client/init'
import { EbookLogEnum } from '@interfaces/EbookLogEnum'
import { SortEnum } from '@interfaces/SortEnum'
import { WishlistResponse } from '@models/ebook/WishlistResponse'
import { WishlisEnum } from '@models/ebook/WishlishEnum'
import { CREATE_EBOOK_WISHLIST_LOG } from '@client/collections/Ebook/schemas/createEbookWishlistLogs'
import { BookEnum } from '@interfaces/BookEnum'
import { GET_MY_PURCHASED_EBOOK } from '@client/collections/Ebook/schemas/getMyPurchasedEbooks'
import { EbookPurchaseResponse } from '@models/ebook/EbookPurchaseResponse'

export function useWishlistAction() {
  async function getWishlistLogs({
    limitPerPage,
    page,
    type,
    searchText,
    sortKey,
    sortValue,
    categoryId,
    display,
    bookType,
  }: {
    limitPerPage: number
    page: number
    type: EbookLogEnum
    searchText: string
    sortKey: string
    sortValue: SortEnum
    categoryId: string
    display?: string
    bookType?: string
  }): Promise<WishlistResponse> {
    const res = await gqlApiInstance.request(GET_MY_EBOOK_WISHLIST_LOGS, {
      limitPerPage,
      page,
      type,
      searchText,
      orderBy: {
        [sortKey]: sortValue,
      },
      bookTypes: bookType
        ? [bookType]
        : bookType === ''
        ? [BookEnum.MANGA, BookEnum.NOVEL]
        : undefined,
      categoryId: Number(categoryId) || undefined,
      hasPromotion: !!display,
    })

    return plainToInstance(WishlistResponse, res.getMyEbookWishlistLogs)
  }
  async function getMyPurchasedEbooks({
    limitPerPage,
    page,
    searchText,
    sortKey,
    sortValue,
    categoryId,
  }: {
    limitPerPage: number
    page: number
    searchText: string
    sortKey: string
    sortValue: SortEnum
    categoryId: string
  }): Promise<EbookPurchaseResponse> {
    const res = await gqlApiInstance.request(GET_MY_PURCHASED_EBOOK, {
      limitPerPage,
      page,
      searchText,
      orderBy: {
        [sortKey]: sortValue,
      },
      categoryIds: Number(categoryId) || undefined,
    })

    return plainToInstance(EbookPurchaseResponse, res.myPurchasedEbooks)
  }

  async function toggleWishlist(
    ebookId: number,
    types: EbookLogEnum
  ): Promise<WishlisEnum> {
    const { createEbookWishlistLogs } = await gqlApiInstance.request(
      CREATE_EBOOK_WISHLIST_LOG,
      {
        createEbookWishlistLogsInput: { ebookId, types },
      }
    )

    return createEbookWishlistLogs.deletedAt === null
      ? WishlisEnum.CREATE
      : WishlisEnum.REMOVE
  }

  return {
    getWishlistLogs,
    toggleWishlist,
    getMyPurchasedEbooks,
  }
}
