import { useRef, useState } from 'react'
import { StarRatingAcceptProps, StarRatingProps } from './interface'

export const withStarRating = (Component: React.FC<StarRatingProps>) => {
  function WithStarRating({
    className,
    precision,
    totalStar,
    activeStar,
    viewOnly,
    starsWidth,
    starHeight,
    onActiveStarChange,
  }: StarRatingAcceptProps) {
    const [hoverActiveStar, setHoverActiveStar] = useState(-1)
    const [isHovered, setIsHovered] = useState(false)
    const ratingContainerRef = useRef<HTMLDivElement>(null)

    const calculateRating = (
      e: React.MouseEvent<HTMLDivElement, MouseEvent>
    ) => {
      if (ratingContainerRef.current) {
        const { width, left } =
          ratingContainerRef.current.getBoundingClientRect()
        const percent = (e.clientX - left) / width
        const numberInStars = percent * totalStar
        const nearestNumber =
          Math.round((numberInStars + precision / 2) / precision) * precision

        return Number(
          nearestNumber.toFixed(precision.toString().split('.')[1]?.length || 0)
        )
      }
      return 0
    }

    const handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      setIsHovered(false)
      if (onActiveStarChange) {
        onActiveStarChange(calculateRating(e))
      }
    }

    const handleMouseMove = (
      e: React.MouseEvent<HTMLDivElement, MouseEvent>
    ) => {
      setIsHovered(true)
      setHoverActiveStar(calculateRating(e))
    }

    const handleMouseLeave = (
      e: React.MouseEvent<HTMLDivElement, MouseEvent>
    ) => {
      setHoverActiveStar(-1) // Reset to default state
      setIsHovered(false)
    }

    const newProps = {
      className,
      starsWidth,
      starHeight,
      ratingContainerRef,
      totalStar,
      isHovered,
      hoverActiveStar,
      activeStar,
      viewOnly,
      handleClick,
      handleMouseMove,
      handleMouseLeave,
    }

    return <Component {...newProps} />
  }
  return WithStarRating
}
