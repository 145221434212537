import { Expose, Type } from 'class-transformer'
import { EbookPurchaseType } from './EbookPurchaseType'

export class EbookPurchaseResponse {
  @Expose()
  @Type(() => EbookPurchaseType)
  data: EbookPurchaseType[]

  @Expose() total: number

  @Expose() page: number
}
