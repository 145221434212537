import cn from 'classnames'
import Image from 'next/image'

import { EbookImageProps } from './interface'

export function EbookImage({
  className,
  imageUrl,
  alt,
  isRead = false,
}: EbookImageProps) {
  return (
    <div className={cn('relative', className)}>
      <div className='relative w-full h-full pt-[140.625%]'>
        <div className='absolute inset-0 rounded-tl-[9%] rounded-br-[9%] shadow-eBook' />
        <Image
          src='/images/ebook.webp'
          layout='fill'
          alt='ebook image'
          sizes='(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw'
          quality={20}
        />
      </div>
      <div className='absolute w-[95.2%] top-0 right-0'>
        <div className='relative w-full h-full pt-[140.625%]'>
          <Image
            src={imageUrl}
            layout='fill'
            alt={`ebook image ${alt}`}
            sizes='(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw'
            quality={20}
          />
        </div>
      </div>
      {/* TODO เอาลิ้น Ebook ออก ถ้าต้องการเอาลิ้น Ebook กลับมาให้ใช้ !isRead  */}
      <div
        className={cn('w-[4%] absolute bottom-[-5%] right-[15%]', {
          hidden: true,
        })}
      >
        <div className='relative w-full h-full p-[117%]'>
          <Image
            src='/images/e-book-read.webp'
            layout='fill'
            alt='ebook read'
            sizes='(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw'
            quality={20}
          />
        </div>
      </div>
    </div>
  )
}
