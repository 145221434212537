import React, { useMemo } from 'react'
import { useMutation } from 'react-query'
import { useRouter } from 'next/router'

import { useModal } from '@hooks/contexts/ModalContext/ModalContext'
import { SearchByEnum } from '@interfaces/SearchByEnum'
import { useAuthentication } from '@hooks/useAuthentication'
import { useTagAction } from '@hooks/tag/useTagAction'
import { EbookCardAcceptProps, EbookCardProps } from './interface'

export function withEbookCard(Component: React.FC<EbookCardProps>) {
  function WithEbookCard({
    id,
    categoryId,
    bookType,
    category,
    tags,
    isShowStatus,
    price,
    status,
    direction = 'horizontal',
    precision = 0.5,
    totalStars = 5,
    isRead = false,
    ...props
  }: EbookCardAcceptProps) {
    const { user, isAuthenticated } = useAuthentication()
    const router = useRouter()
    const modal = useModal({ modal: 'categoryModal' })
    const tagClient = useTagAction()
    const isReplace =
      router.pathname.includes('/search') && router.pathname !== '/'

    const categoryPath = useMemo(() => {
      if (categoryId) {
        return {
          pathname: '/category',
          query: {
            id: categoryId,
            ...(bookType !== 'EBOOK' ? { bookType } : { isEbook: true }),
          },
        }
      }
      return {}
    }, [bookType, categoryId])

    const { mutate: createUserTagClick } = useMutation((tagsId: number) =>
      tagClient.createUserTagClick({
        userId: isAuthenticated ? user.id : undefined,
        tagsId,
        categoriesId: categoryId,
      })
    )

    function handleGoToSearchTagPage(tagName: string) {
      if (isReplace) {
        return {
          pathname: '/search/ebook',
          query: {
            ...router.query,
            text: tagName,
            tab: SearchByEnum.TAG,
          },
        }
      }

      return {
        pathname: '/search/ebook',
        query: {
          text: tagName,
          tab: SearchByEnum.TAG,
        },
      }
    }

    function showCategoryDialog() {
      modal.show({
        category,
        tags,
        categoryPath,
        handleGoToSearchTagPage,
        createUserTagClick,
      })
    }

    async function handleClickEbookCard() {
      await router.push(`/ebook/${id}`)
    }

    const componentProps = {
      id,
      categoryId,
      bookType,
      category,
      tags,
      status,
      direction,
      categoryPath,
      price,
      isShowStatus,
      isReplace,
      isRead,
      precision,
      totalStars,
      showPrice: price !== undefined,
      showCategoryDialog,
      handleGoToSearchTagPage,
      createUserTagClick,
      handleClickEbookCard,
      ...props,
    }

    return <Component {...componentProps} />
  }

  return WithEbookCard
}
