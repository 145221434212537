import { gql } from 'graphql-request'

export const GET_MY_EBOOK_WISHLIST_LOGS = gql`
  query GetMyEbookWishlistLogs(
    $limitPerPage: Int = 10
    $page: Int = 1
    $type: EbookLogsType!
    $searchText: String
    $categoryId: Float
    $bookTypes: [BookType!]!
    $orderBy: EbookWishListOrderBy!
    $hasPromotion: Boolean
  ) {
    getMyEbookWishlistLogs(
      limitPerPage: $limitPerPage
      page: $page
      type: $type
      searchText: $searchText
      categoryId: $categoryId
      bookTypes: $bookTypes
      orderBy: $orderBy
      hasPromotion: $hasPromotion
    ) {
      data {
        id
        ebookId
        types
        isReaded
        ebook {
          id
          title
          penName {
            firstPenName
          }
          coverImgPath
          cover
          bookType
          avgRating
          reviewCount
          wishlistCount
          price
          category {
            id
            name
          }
          promotion {
            id
            discountPrice
            discountPercent
            startPublishedAt
            endPublishedAt
            price
            isPublish
            discountCoin
          }
          tags {
            id
            name
          }
        }
        user {
          profilePageSlug
        }
      }
      total
      page
    }
  }
`
