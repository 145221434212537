import { gql } from 'graphql-request'

export const GET_MY_PURCHASED_EBOOK = gql`
  query GetMyPurchasedEbooks(
    $orderBy: GetMyPurchasedEbooksInputOrderBy
    $searchText: String
    $page: Int
    $limitPerPage: Int
    $categoryIds: [Int!]
  ) {
    myPurchasedEbooks(
      orderBy: $orderBy
      searchText: $searchText
      page: $page
      limitPerPage: $limitPerPage
      categoryIds: $categoryIds
    ) {
      page
      total
      data {
        id
        title
        penName {
          firstPenName
          user {
            profilePageSlug
          }
        }
        coverImgPath
        cover
        bookType
        avgRating
        reviewCount
        wishlistCount
        price
        category {
          id
          name
        }
        promotion {
          id
          discountPrice
          discountPercent
          startPublishedAt
          endPublishedAt
          price
          isPublish
          discountCoin
        }
        tags {
          id
          name
        }
        isWishlist
      }
    }
  }
`
